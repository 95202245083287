import React from "react";
import "./ProposalTestimonial.css";
import User4 from "../assets/user4.jpg";
import User5 from "../assets/user5.jpg";
import User6 from "../assets/user6.jpg";

const ProposalTestimonial = () => {
  return (
    <>
      <div className="testimonial-cont">
        <h1 className="testimonials-header">Testimonials</h1>
        <div className="card-cont">
          <div className="cards">
            <p className="r-text">
              "The "Research Proposal Writing" workshop helped me structure my
              proposal with clarity and confidence. The step-by-step guidance
              about how to write an impactful abstract and methodology was
              invaluable for my PhD proposal!"
            </p>
            <p className="r-name">
              <img src={User4} alt="User-dp" /> – Ethan, PhD Student, USA
            </p>
          </div>
          <div className="cards">
            <p className="r-text">
              "I was struggling with choosing a research topic and structuring my proposal, but this workshop made everything so much clearer. The step-by-step guidance and AI tool mentioned in the session were game-changers for me. Highly recommend it to anyone feeling overwhelmed by the process!"
            </p>
            <p className="r-name">
              <img src={User5} alt="User-dp" /> – Charlotte, Master's Student,
              UK
            </p>
          </div>
          <div className="cards">
            <p className="r-text">
              "This workshop covered everything I needed to know about writing a
              solid research proposal. The instructors explained complex
              concepts in a simple way, and the free Turnitin check was a great
              bonus. I feel much more confident now!"
            </p>
            <p className="r-name">
              <img src={User6} alt="User-dp" /> – Carlos Ramirez, Master's
              Student (Spain)
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProposalTestimonial;
