import React from "react";
import "./Event.css";
import ProposalImg from "../assets/event-proposal.webp";
import AnalysisImg from "../assets/event-analysis.webp";
import { Helmet } from "react-helmet-async";

const Event = () => {
  return (
    <div className="event-container">
      {/* Meta tags */}
      <Helmet>
        <title>
          ResearchPro Events: Workshops on Proposals & Data Analysis
        </title>
        <meta
          name="description"
          content="Explore ResearchPro events! Join our Research Proposal Workshop and Data Analysis sessions to master essential academic and research skills.
"
        />
      </Helmet>

      <div className="event-box1">
        <div className="content-div">
          <h3>Data Analysis Workshop</h3>
          <ul>
            <li>
              Master qualitative and quantitative data analysis techniques in a
              single workshop.
            </li>
            <li>
              Gain practical skills from experts and earn a certification.
            </li>
            <li>
              Register early to unlock advanced techniques and priority support.
            </li>
          </ul>
          <a href="/analysis">Data Analysis</a>
        </div>
        <div className="img-div">
          <a href="/analysis">
            <img src={AnalysisImg} alt="Event" />
          </a>
        </div>
      </div>
      <div className="event-box1">
        <div className="content-div">
          <h3>Research Proposal Writing Workshop</h3>
          <ul>
            <li>Craft impactful research proposals with expert guidance.</li>
            <li>
              Utilize AI tools for seamless topic selection and structuring.
            </li>
            <li>Early birds get a free Turnitin check and bonus Q&A.</li>
          </ul>
          <a href="/upcoming">Research Proposal</a>
        </div>
        <div className="img-div">
          <a href="/upcoming">
            <img src={ProposalImg} alt="Event" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Event;
