import React from "react";
import "./AnalysisFactScreen.css";

const AnalysisFactScreen = ({ fact, onContinue, factImage }) => {
  return (
    <div className="analysis-fact-screen">
      {factImage && (
        <img
          src={factImage.url}
          alt="Fact related visual"
          className="fact-image"
        />
      )}
      <div className="fact-content">
        <h2>Did You Know?</h2>
        <p className="fact-p">{fact}</p>
        <button className="fact-btn" onClick={onContinue}>
          Continue
        </button>
      </div>
    </div>
  );
};

export default AnalysisFactScreen;
