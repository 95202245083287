import React from "react";
import "./AnalysisQuizIntro.css";
import Welcome from "../../assets/Welcome.webp";

const AnalysisQuizIntro = ({ onNext }) => {
  return (
    <div className="analysis-intro-section">
      <img src={Welcome} className="analysis-intro-image" alt="Welcome" />
      <h2>Are You Ready to Master Data Analysis?</h2>
      <p>
        Take this quick quiz to see if our workshop is the key to unlocking your
        data analysis success!
      </p>
      <button className="analysis-intro-btn" onClick={onNext}>
        Next
      </button>
    </div>
  );
};

export default AnalysisQuizIntro;
