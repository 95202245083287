// UserForm.js
import React from "react";
import ThankYou from "../../assets/thank-you.webp";
import "./ProposalQuizForm.css";

const UserForm = ({ userData, onChange, onSubmit }) => (
  <div className="quiz-form-cont">
    <img className="thankyou-img" src={ThankYou} alt="Thank You" />
    <form className="user-form" onSubmit={onSubmit}>
      <h2>We'd love to hear from you!</h2>
      <label htmlFor="name">Name:</label>
      <input
        type="text"
        id="name"
        name="name"
        value={userData.name}
        onChange={onChange}
        required
      />
      <label htmlFor="email">Email:</label>
      <input
        type="email"
        id="email"
        name="email"
        value={userData.email}
        onChange={onChange}
        required
      />
      {/* Hidden input field */}
      <input
        type="hidden"
        id="QuizName"
        name="Research-Proposal-Quiz"
        value={userData.hiddenValue} 
      />
      <button className="quiz-submit-btn" type="submit">
        Submit
      </button>
    </form>
  </div>
);

export default UserForm;
