import React from 'react'
import './footer.css';
import { FaFacebook, FaLinkedin, FaPaypal, FaEnvelope } from "react-icons/fa";
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <div className="footer">
      <br />
      <h1>Research Pro</h1>
      <p>About Us: </p>

      <p className="footer-p">
        At ResearchPro, we are dedicated to supporting PhD and master’s students
        in their research journey. Our platform offers expert guidance,
        extensive resources, and a vibrant community to help you achieve
        academic excellence.
      </p>
      <p>
        <span className="social-media">
          <a
            href="https://www.facebook.com/profile.php?id=61551675300150"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaFacebook size={30} color="blue" />
          </a>
        </span>
        <span className="social-media">
          <a
            href="https://www.linkedin.com/company/researchevents/?viewAsMember=true"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaLinkedin size={30} color="blue" />
          </a>
        </span>
        <span className="social-media">
          <a
            href="mailto:info@researchpro.online"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FaEnvelope size={30} color="blue" />
          </a>
        </span>
      </p>
      <p>Payment partner:</p>

      <p>
        <Link to="https://www.paypal.com/gd/home">
          <FaPaypal size={50} />
        </Link>
      </p>
      <p>
        © <strong>2024 ResearchPro. All rights reserved.</strong>
      </p>
    </div>
  );
}

export default Footer