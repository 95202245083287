import React, { useEffect, useState } from "react";
import "./overlay.css";
import "./form.css";
import "./UpcomingEvent.css";
import ProposalTestimonial from "./ProposalTestimonial";
import Research_Pro_Logo from "../assets/Research_Pro_Logo_2.webp";
import ResearchProposal from "../assets/ResearchProposal.webp";
import ResearchProposalMobile from "../assets/ResearchProposal-Mobile.webp";
import QuizImg from "../assets/quiz.png";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import countryList from "react-select-country-list";
import Select from "react-select";
import Cookies from "js-cookie"; // Import js-cookie
import {
  RiCloseCircleFill,
  RiFacebookCircleFill,
  RiWhatsappFill,
  RiLinkedinBoxFill,
  RiMailFill,
  RiTwitterXFill,
  RiShare2Fill,
  RiCloseLine,
} from "@remixicon/react";

const UpcomingEvent = () => {
  const [latestEvent, setLatestEvent] = React.useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isPayPalVisible, setIsPayPalVisible] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    curriculumUser: "",
    email: "",
    CurriculumName: "Research-Proposal-Curriculum",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [showQuizPopup, setShowQuizPopup] = useState(false);
  const MIN_VALUE = 3586;
  const [counter, setCounter] = useState(() => {
    const storedCounter = Cookies.get("counter");
    return storedCounter
      ? Math.max(parseInt(storedCounter, 10), MIN_VALUE)
      : MIN_VALUE;
  });

  const handleAddToCounter = () => {
    setCounter((prevCounter) => {
      const newCounter = prevCounter + 1;
      const finalCounter = Math.max(newCounter, MIN_VALUE);
      Cookies.set("counter", finalCounter, { expires: 365 });
      return finalCounter;
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowQuizPopup(true);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  const handleClosePopup = () => {
    setShowQuizPopup(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleCurriculumSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await axios.post(
        "https://researchpro.online/upcoming/curriculum-download",
        formData
      );
      setShowForm(false);
      alert("The curriculum has been emailed to you successfully!");
    } catch (error) {
      console.error("Error saving user or sending email:", error);
      alert(
        "An error occurred while processing your request. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const handleShare = (platform) => {
    const url = encodeURIComponent(window.location.href);
    let shareUrl = "";

    switch (platform) {
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?url=${url}&text=Check%20this%20out!`;
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${url}`;
        break;
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=Check this out: ${url}`;
        break;
      case "email":
        shareUrl = `mailto:?subject=Check%20this%20out&body=Check%20this%20out: ${url}`;
        break;
      default:
        break;
    }

    window.open(shareUrl, "_blank");
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(latestEvent);
    const eventId = latestEvent?._id;
    const workshopId = "Research Proposals";
    const country = selectedCountry?.label || "Not specified";

    try {
      const response = await axios.post(
        "https://researchpro.online/formSubmit",
        { name, email, eventId, workshopId, country }
      );
      if (response.status === 200) {
        localStorage.setItem("email", email);
        setIsPayPalVisible(true);
        setIsFormSubmitted(true);
      }
      console.log(name, email);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const location = useLocation();

  useEffect(() => {
    async function fetchLatestEvent() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/get-latest-event`
        );
        const event = response.data;
        setLatestEvent(event);
      } catch (error) {
        console.error("Error fetching latest event:", error);
      }
    }
    fetchLatestEvent();
    if (location.pathname === "/upcoming") {
      document.body.style.backgroundColor = "#ffffff";
    } else {
      document.body.style.backgroundColor = "#d7c6f3";
    }

    return () => {
      document.body.style.backgroundColor = "";
    };
  }, [location.pathname]);

  // const convertBufferToBlobUrl = (buffer, contentType) => {
  //   // console.log("Buffer:", buffer);
  //   // console.log("Content Type:", contentType);
  //   if (buffer && contentType) {
  //     const byteArray = new Uint8Array(buffer.data);
  //     const blob = new Blob([byteArray], { type: contentType });
  //     const blobUrl = URL.createObjectURL(blob);
  //     // console.log("Generated Blob URL:", blobUrl);
  //     return blobUrl;
  //   }
  //   return null;
  // };

  // const imageSrc = useMemo(() => {
  //   return convertBufferToBlobUrl(
  //     latestEvent.image?.data,
  //     latestEvent.image?.contentType
  //   );
  // }, [latestEvent.image?.data, latestEvent.image?.contentType]);

  useEffect(() => {
    if (isPayPalVisible) {
      const script = document.createElement("script");
      script.src =
        "https://www.paypal.com/sdk/js?client-id=BAAYWXYvMr4CcyPUugFMlYcVLnq3IJBmWAmkw2ExaA4hGWM83mOa4sfikVNFUT8HkyjKKC22ihzRZoLWSw&components=hosted-buttons&disable-funding=venmo&currency=USD";
      script.addEventListener("load", () => {
        window.paypal
          .HostedButtons({
            hostedButtonId: "GA5HXEM6PM67J",
          })
          .render("#paypal-container-GA5HXEM6PM67J");
      });
      document.body.appendChild(script);
    }
  }, [isPayPalVisible]);

  return (
    <>
      {latestEvent && (
        <div className="upcoming-event-container">
          {/* Meta tags */}
          <Helmet>
            <title>Research Proposal & AI Workshop | ResearchPro</title>
            <meta
              name="description"
              content="Join ResearchPro's specialized workshops on research proposal writing and AI techniques. Enhance your skills for academic and research success."
            />
          </Helmet>

          {showQuizPopup && (
            <div className="quiz-popup">
              <div className="quiz-popup-content">
                <RiCloseLine
                  className="quiz-popup-close"
                  color="#D1410C"
                  size={38}
                  style={{ cursor: "pointer" }}
                  onClick={handleClosePopup}
                />
                <div className="quiz-popup-left">
                  <img src={QuizImg} alt="quizimg" />
                </div>
                <div className="quiz-popup-right">
                  <h3>
                    <span>👥{counter} </span>
                    People have Played the Quiz!
                  </h3>
                  <h2>It's Your Chance Now.</h2>
                  <a
                    href="/proposal-quiz"
                    onClick={(e) => {
                      e.preventDefault();
                      handleAddToCounter();
                      setTimeout(() => {
                        window.location.href = "/proposal-quiz";
                      }, 1000);
                    }}
                  >
                    Test Your Knowlegde!
                  </a>
                </div>
              </div>
            </div>
          )}

          <div className="header">
            {!ResearchProposal ? (
              <div className="skeleton-loader">Loading...</div>
            ) : (
              <img
                loading="lazy"
                src={isMobile ? ResearchProposalMobile : ResearchProposal}
                alt="EventImage"
              />
            )}
          </div>

          <div className="event-details">
            <div className="event-details-header">
              <div className="left-half-event-details">
                <h2 className="page-title">
                  Conquer Your Research Proposal: From Title Selection to Final
                  Formatting
                </h2>
                {isOpen && (
                  <div className="share-popup-overlay">
                    <div className="share-popup">
                      <div className="share-head">
                        <h3>Share With Friends</h3>
                        <RiCloseCircleFill
                          color="#d1410c"
                          style={{ cursor: "pointer" }}
                          onClick={togglePopup}
                        />
                      </div>
                      <div className="share-cont">
                        <div className="share-button-cont">
                          <button
                            className="share-button"
                            onClick={() => handleShare("facebook")}
                          >
                            <RiFacebookCircleFill />
                          </button>
                          <button
                            className="share-button"
                            onClick={() => handleShare("twitter")}
                          >
                            <RiTwitterXFill />
                          </button>
                          <button
                            className="share-button"
                            onClick={() => handleShare("linkedin")}
                          >
                            <RiLinkedinBoxFill />
                          </button>
                          <button
                            className="share-button"
                            onClick={() => handleShare("whatsapp")}
                          >
                            <RiWhatsappFill />
                          </button>
                          <button
                            className="share-button"
                            onClick={() => handleShare("email")}
                          >
                            <RiMailFill />
                          </button>
                        </div>
                        <div className="link-copy-container">
                          <input
                            type="text"
                            className="link-box"
                            value={window.location.href}
                            readOnly
                          />
                          <button
                            className="copy-button"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                window.location.href
                              );
                            }}
                          >
                            Copy Link
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="right-half-event-details">
                <div className="payment-info">
                  <div className="box larger-than-800">
                    <div>
                      <span className="original-price">$149</span>{" "}
                      <span className="discounted-price">
                        Early Bird Price: $99.99
                      </span>
                    </div>
                    <a className="button" href="#popup1">
                      <button>Register Now</button>
                    </a>
                  </div>
                </div>
              </div>

              {/* Second box for screen sizes less than 800px  */}

              <div className="box smaller-than-800">
                <span className="discounted-price">
                  Early Bird Price @99.99 USD
                </span>
                <span className="discounted-price">
                  Bonus QnA session and Free Plagiarism Test.
                </span>
                <a className="button" href="#popup1">
                  <button>Register Now</button>
                </a>
              </div>

              <div id="popup1" className="overlay">
                <div className="popup">
                  <h2>Register Now</h2>
                  <a className="close" href="#a">
                    ×
                  </a>
                  <div className="content">
                    {!isFormSubmitted && (
                      <form
                        name="contact"
                        method="POST"
                        netlify
                        data-netlify="true"
                        onSubmit={(event) => handleSubmit(event)}
                      >
                        <input type="hidden" name="form-name" value="contact" />
                        <input
                          type="hidden"
                          name="workshopId"
                          value="Research Purposal"
                        />
                        <label htmlFor="name">Name:</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          onChange={(e) => setName(e.target.value)}
                          required
                        />

                        <label htmlFor="email">Email:</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                        <label htmlFor="country">Country:</label>
                        <Select
                          options={countryList().getData()}
                          value={selectedCountry}
                          onChange={setSelectedCountry}
                          className="country-select"
                          required
                        />
                        <button type="submit">Continue Checkout</button>
                      </form>
                    )}

                    {isPayPalVisible && (
                      <div
                        id="paypal-container-GA5HXEM6PM67J"
                        style={{ display: "block" }}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="yellow-strip">
              <h6>Workshop Date: 1 Feburary 2025</h6>
              <RiShare2Fill
                color="#1E0A3C"
                className="share-icon"
                style={{
                  height: "2.2vmax",
                  width: "2.2vmax",
                  cursor: "pointer",
                }}
                onClick={togglePopup}
              />
              <a href="/proposal-quiz">Test Your Knowledge!</a>
            </div>

            <div className="proposal-display">
              <h2>Writing your Research Proposal can be Intimidating</h2>
              <ul>
                <li>🧐 What if your topic is too broad?</li>
                <li>
                  🔍 What if your methodology doesn't align with your
                  Objectives?
                </li>
                <li>
                  ❌ What if you are making common mistakes, you didn't even
                  know that you were making?
                </li>
              </ul>
            </div>
            <div className="key-takeaways">
              <h2> About The Workshop</h2>
              <p className="about-info">
                This workshop is specifically designed to help you overcome the
                fears and avoid the pitfalls that often lead to proposal
                disapproval. Whether you’re struggling to choose the right
                topic, unsure how to structure your sections, or worried about
                aligning with academic standards, we’ve got you covered.
              </p>
              <p className="about-info">
                With a focus on interactive learning and cutting-edge AI tools,
                you'll gain practical skills to structure your proposal
                effectively and make a lasting impact. From selecting the right
                topic to writing each section with clarity and purpose, this
                workshop ensures you’ll walk away with confidence and a solid
                understanding of the process
              </p>
            </div>

            <div className="key-takeaways">
              <h2>Comprehensive Curriculum:</h2>
              <ul>
                <li>
                  <strong>Session 1:</strong> Introduction to Research Proposal.
                </li>
                <li>
                  <strong>Session 2:</strong> Choosing a Research Title.
                </li>
                <li>
                  <strong>Session 3:</strong> Writing an Introduction.
                </li>
                <li>
                  <strong>Session 4:</strong> Writing the Literature Review.
                </li>
                <li>
                  <strong>Session 5:</strong> Choosing the Methodology.
                </li>
                <li>
                  <strong>Session 6:</strong> Formulating Expected Outcomes.
                </li>
              </ul>
              <button
                className="curriculum-download-btn"
                onClick={() => setShowForm(true)}
              >
                Download Detailed Curriculum
              </button>
            </div>

            {showForm && (
              <div className="modal">
                <form onSubmit={handleCurriculumSubmit}>
                  <RiCloseCircleFill
                    className="modal-close"
                    color="#000"
                    size={35}
                    style={{ cursor: "pointer" }}
                    onClick={() => setShowForm(false)}
                  />
                  <h3>Enter Your Details</h3>
                  <p>
                    We will Send the Detailed Curriculum to the Email Address
                    you Provide.
                  </p>
                  <input
                    type="hidden"
                    id="CurriculumName"
                    name="CurriculumName"
                    value="Research-Proposal-Curriculum"
                  />
                  <input
                    type="text"
                    name="curriculumUser"
                    id="curriculumUser"
                    placeholder="Your Name"
                    value={formData.curriculumUser}
                    onChange={handleInputChange}
                    required
                  />
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                  {isSubmitting ? (
                    <div class="curriculum-loader"></div>
                  ) : (
                    <button type="submit" className="modal-submit">
                      Submit & Download
                    </button>
                  )}
                </form>
              </div>
            )}

            <div className="key-takeaways">
              <h2 className="bonus">
                Bonus for Early Birds (Enroll at an earliest to avail):
              </h2>
              <ul>
                <li>
                  <strong>Bonus QnA session to resolve your queries.</strong>
                </li>
                <li>
                  <strong>
                    Free Turnitin check of your proposal to check Plagiarism and
                    AI.
                  </strong>
                </li>
              </ul>
            </div>

            <div className="key-takeaways">
              <h2>Key Features of the Workshop Include:</h2>
              <ul>
                <li>
                  <strong>Step-by-Step Curriculum:</strong> Covering everything
                  from choosing a research topic to planning your methodology
                  and outcomes.
                </li>
                <li>
                  <strong>Practical Tools:</strong> Learn to use AI-driven tools
                  to simplify complex tasks and enhance your proposal.
                </li>
                <li>
                  <strong>Beginner-Friendly Approach:</strong> Perfect for those
                  with little or no experience in proposal writing.
                </li>
                <li>
                  <strong>Flexible Access:</strong> Complete the workshop on
                  your schedule, with recordings available anytime.
                </li>
                <li>
                  <strong>Certification:</strong> Showcase your newly acquired
                  skills with a certificate of completion.
                </li>
              </ul>
            </div>

            <div className="key-takeaways">
              <h2>Workshop Details:</h2>
              <ul>
                <li>
                  <strong>Date:</strong> 1 Feburary 2025.
                </li>
                <li>
                  <strong>Type:</strong> Self Paced.
                </li>
              </ul>
            </div>

            <div className="key-takeaways">
              <h2>Fee Structure:</h2>
              <ul>
                <li>
                  <strong>Early Bird Price: </strong> $99.99 (If Registered
                  Before 29 January) <br />
                  Please note: Early birds will receive a bonus session from our
                  end.
                </li>
                <li>
                  <strong>Regular Price: </strong> $149 (After 29 January)
                </li>
              </ul>
            </div>

            <h2 className="bottom-h2">Ready to Start Your Research Journey?</h2>
            <ul
              style={{ listStyle: "disc", marginLeft: "-1vw" }}
              className="ul-why-join"
            >
              <li>
                Register now and take the first step towards mastering your
                research proposal!
              </li>
            </ul>
            <ProposalTestimonial />
            <div className="organizer-cont">
              <h3>Organised By-</h3>
              <div className="organizer-box">
                <div className="organizer-up">
                  <img src={Research_Pro_Logo} alt="ResearchPro Logo" />

                  <a href="/contactus">Contact Us</a>
                </div>
                <div className="organizer-down">
                  <h4>About Organisers</h4>
                  <p>
                    ResearchPro is a leading platform dedicated to empowering
                    PhD and master’s students in their research journey. With
                    expert-led webinars on proposal writing, research papers,
                    data analysis, and more, we provide valuable insights and
                    practical tools for academic success. Join ResearchPro and
                    unlock your full potential!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default UpcomingEvent;
