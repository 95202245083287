import React from "react";
import "./QuizIntro.css"
import Welcome from "../../assets/Welcome.webp";

const QuizIntro = ({ onNext }) => {
  return (
    <div className="intro-section">
      <img src={Welcome} className="intro-image" alt="Welcome" />
      <h2>Are You Ready to Master Research Proposal Writing?</h2>
      <p>
        Take this quick quiz to discover if our workshop can fast-track your
        proposal writing success!
      </p>
      <button className="intro-btn" onClick={onNext}>
        Next
      </button>
    </div>
  );
};

export default QuizIntro;
